/* HeroCarousel.css */

/* Set a shorter height for mobile devices */
@media (max-width: 768px) {
	.custom-carousel {
	  height: 200px; /* Adjust the height as needed for mobile devices */
	
  }
}

  

/* Custom styles for arrows as circles */
.custom-arrow {
	background-color: transparent;
	color: white;
	font-size: 24px;
	border: 2px solid white; /* Add a border for a circular shape */
	border-radius: 50%; /* Makes it circular */
	width: 40px;
	height: 40px;
	cursor: pointer;
	transition: background-color 0.2s;
  }
  
  .custom-arrow:hover {
	background-color: rgba(0, 0, 0, 0.8);
  }




.carousel-text-container {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center; /* Center the content */
}

.white-box {
  background-color: white;
  opacity: 0.9; /* Adjust opacity as needed */
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto; /* Center the box horizontally */
  
}

.carousel-title {
  font-weight: bold;
  font-size: 36px;
  color: black;
}

.carousel-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  text-decoration: none;
  border-radius: 4px;
}

.carousel-button:hover {
  background-color: #333;
  color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {


  .carousel-title {
    display: inline-block;
    padding: 10px 20px;

    color: #333;
    font-size: 16px;
    text-decoration: none;
    border-radius: 4px;
  }


}
